<template>
  <div class="new-detail">
    <header class="page-header">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <span>公司简介</span>
      <span></span>
    </header>
    <div class="article">
      <div class="img"><img :src="CompanyPresentationImg" /></div>
      <div class="title">{{ CompanyName }}</div>

      <div class="desc">{{ CompanyResume }}</div>

      <div class="content">
        <h5>联系方式</h5>
        <div>联系人： {{ CompanyLinkMan }}</div>
        <div>电话： {{ CompanyTel }}</div>
        <div>手机： {{ CompanyPhone }}</div>
        <div>邮箱： {{ CompanyMail }}</div>
        <div>QQ： {{ CompanyQQ }}</div>
        <div>微信：{{ CompanyWechat }}</div>
        <div class="set-center">
          <img style="width: 150px; height: 150px" :src="WechatPicUrl" />
        </div>
        <hr />
        <h5>公司愿景</h5>
        <div>{{ CompanyVision }}</div>
        <h5>公司宗旨</h5>
        <div>{{ CompanyMission }}</div>
        <h5>社会责任</h5>
        <div>{{ CompanyObligation }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { BASE_URL } from '@/api/index.js';
import { getFirmInfo } from '@/api/website.js';
// import { formatDate } from '@/utils/index.js';
// function getValidTime(value) {
//   if (value == null || value == '') return '';
//   var myDate = new Date(value.match(/\d+/)[0] * 1);
//   return formatDate(myDate);
// }
export default {
  data() {
    return {
      CompanyName: '',
      CompanyObligation: '',
      CompanyPhone: '',
      CompanyPresentationImg: '',
      CompanyQQ: '',
      CompanyResume: '',
      CompanySummary: '',
      CompanyTel: '',
      CompanyVision: '',
      CompanyWechat: '',
      WechatPicUrl: '',
      CompanyMission: '',
      CompanyMail: '',
      CompanyLinkMan: '',
      CompanyAddr: '',
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {},
  methods: {
    htmlDecode(text) {
      //1.首先动态创建一个容器标签元素，如DIV
      var temp = document.createElement('div');
      //2.然后将要转换的字符串设置为这个元素的innerHTML(ie，火狐，google都支持)
      temp.innerHTML = text;
      //3.最后返回这个元素的innerText(ie支持)或者textContent(火狐，google支持)，即得到经过HTML解码的字符串了。
      var output = temp.innerText || temp.textContent;
      temp = null;
      console.log(output);
      return output;
    },
    loadData() {
      getFirmInfo().then(res => {
        if (res.Result == true) {
          let data = res.DataDict['data'];
          console.log(res);
          this.CompanyName = data.CompanyName;
          this.CompanyObligation = data.CompanyObligation;
          this.CompanyPhone = data.CompanyPhone;
          if (data.CompanyPresentationImg.startsWith('http')) {
            this.CompanyPresentationImg = data.CompanyPresentationImg;
          } else {
            this.CompanyPresentationImg =
              BASE_URL + data.CompanyPresentationImg;
          }
          this.CompanyQQ = data.CompanyQQ;
          this.CompanyResume = data.CompanyResume;
          this.CompanySummary = data.CompanySummary;
          this.CompanyTel = data.CompanyTel;
          this.CompanyVision = data.CompanyVision;
          this.CompanyWechat = data.CompanyWechat;
          if (data.WechatPicUrl.startsWith('http')) {
            this.WechatPicUrl = data.WechatPicUrl;
          } else {
            this.WechatPicUrl = BASE_URL + data.WechatPicUrl;
          }
          this.CompanyMission = data.CompanyMission;
          this.CompanyMail = data.CompanyMail;
          this.CompanyLinkMan = data.CompanyLinkMan;
          this.CompanyAddr = data.CompanyAddr;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/_var';
.article {
  margin: rem(20px);
  margin-top: rem(100px);
  line-height: 0.6rem;
  background: #fff;
  max-width: 100%;
  padding: rem(20px);
  .title {
    font-size: rem(40px);
    text-align: left;
    padding: rem(15px);
  }
  .img img {
    width: 100%;
    height: rem(260px);
    border: 1px solid #efefef;
    border-radius: 10px;
  }
  .content {
    img {
      max-width: 100%;
    }
  }
  .author {
    font-size: rem(18px);
    padding: rem(15px);
  }
  img {
    max-width: 100%;
  }
}
</style>
